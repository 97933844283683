// extracted by mini-css-extract-plugin
var _1 = "#00a990";
var _2 = "#666771";
var _3 = "#d3d3d3";
var _4 = "#f4516c";
var _5 = "#36a3f7";
var _6 = "#ebedf2";
var _7 = "#45474d";
var _8 = "#5867dd";
var _9 = "#ff644b";
var _a = "#34bfa3";
var _b = "#ffb822";
export { _1 as "brandColor", _2 as "customGrey", _3 as "customLightGrey", _4 as "dangerColor", _5 as "infoColor", _6 as "inputBorderColor", _7 as "nodeEnergyBlack", _8 as "primaryColor", _9 as "secondaryBrandColor", _a as "successColor", _b as "warningColor" }
